export const SENTRY_HOST = 'o285112.ingest.sentry.io';
export const PROJECT_ID = '5952457'; // web

export const initSentryClient = async () => {
    const Sentry = await import('@sentry/nextjs');
    const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

    // The config you add here will be used whenever a page is visited.
    // https://docs.sentry.io/platforms/javascript/guides/nextjs/
    Sentry.init({
        dsn: SENTRY_DSN || `https://d1e095dfbc384ed3a49a88fbf4ee633f@${SENTRY_HOST}/5952457`,
        // Adjust this value in production, or use tracesSampler for greater control
        tracesSampleRate: 1.0,
        environment: process.env.NEXT_PUBLIC_ENV,
        tunnel: '/api/sentry',
        // ...
        // Note: if you want to override the automatic release value, do not set a
        // `release` value here - use the environment variable `SENTRY_RELEASE`, so
        // that it will also get attached to your source maps
    });
};

interface SentryUser {
    id?: string;
    username?: string;
    email?: string;
    ip_address?: string;
}

export const captureException = async (e: any, tags: any = {}, user?: SentryUser) => {
    const Sentry = await import('@sentry/nextjs');

    if (user) {
        Sentry.setUser(user);
    }

    Sentry.captureException(e, {
        tags,
    });
};
